import React, { useEffect, useState } from "react";
import ParentContainer from "../components/ParentContainer";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";
import Button from "../components/Button";
import { ApiUrl } from "../components/ApiUrl";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const AccordionSetm = ({ title, children, isOpen, onClick }) => {
  return (
    <div className=" rounded-lg w-full">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-8 text-left max-md:px-4"
      >
        <div className="flex gap-2 w-full items-center">
          {" "}
          <span className="text-lg font-medium w-11/12">{title}</span>
          <div className="w-1/12 flex justify-end">
            {isOpen ? (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1668H13.7497C18.333 20.1668 20.1663 18.3335 20.1663 13.7502V8.25016C20.1663 3.66683 18.333 1.8335 13.7497 1.8335H8.24967C3.66634 1.8335 1.83301 3.66683 1.83301 8.25016V13.7502C1.83301 18.3335 3.66634 20.1668 8.24967 20.1668Z"
                  stroke="url(#paint1_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5766"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5766"
                    x1="0.0945021"
                    y1="3.87053"
                    x2="23.3609"
                    y2="5.51313"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 14.6666V7.33325"
                  stroke="url(#paint1_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1666H13.7497C18.333 20.1666 20.1663 18.3333 20.1663 13.7499V8.24992C20.1663 3.66659 18.333 1.83325 13.7497 1.83325H8.24967C3.66634 1.83325 1.83301 3.66659 1.83301 8.24992V13.7499C1.83301 18.3333 3.66634 20.1666 8.24967 20.1666Z"
                  stroke="url(#paint2_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5758"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5758"
                    x1="10.9052"
                    y1="8.14807"
                    x2="12.1805"
                    y2="8.16034"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_590_5758"
                    x1="0.0945021"
                    y1="3.87029"
                    x2="23.3609"
                    y2="5.51289"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </button>
      {isOpen && <div className="px-8 py-4 max-md:px-4">{children}</div>}
    </div>
  );
};

const ProductPage = () => {
  const [openIndex, setOpenIndex] = useState(0); // Initialize to keep the first item open by default
  const { id } = useParams();

  const handleToggle = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    } else if (openIndex == index) {
      setOpenIndex(null);
    }
  };
  const BulletIcon = (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30"
        height="30"
        rx="15"
        fill="url(#paint0_linear_590_6015)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_590_6015"
          x1="-2.84483"
          y1="3.33333"
          x2="35.2274"
          y2="6.02122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
      </defs>
    </svg>
  );

  const [productPageData, setProductPageData] = useState(null);
  const [sourceData, setSourceData] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [loadingHomePage, setLoadingHomePage] = useState(true);
  const [loadingSource, setLoadingSource] = useState(true);
  const [loadingService, setLoadingService] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + "/api/single-product?populate=Power_Points,FAQs"
        );
        setProductPageData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setError(true);
      } finally {
        setLoadingHomePage(false);
      }
    };

    const fetchSourceData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + `/api/products/${id}?populate=Points,Image`
        );

        setSourceData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching source data:", error);
        setError(true);
      } finally {
        setLoadingSource(false);
      }
    };

    const fetchServiceData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/services-and-pricing?populate=Points,Kit_Image,Banking_Image"
        );

        setServiceData(response.data);
        console.log("Service Data", response.data);
      } catch (error) {
        console.error("Error fetching service data:", error);
        setError(true);
      } finally {
        setLoadingService(false);
      }
    };

    fetchSourceData();
    fetchHomePageData();
    fetchServiceData();
  }, []);

  if (loadingHomePage || loadingSource || loadingService) {
    return <div></div>;
  }

  if (error || !productPageData || !sourceData || !serviceData) {
    return <div>Error loading data.</div>;
  }

  const [partOne, partTwo] =
    productPageData.data.attributes.Banner_Text_One.split("||");
  const [partOne1, partTwo1] =
    productPageData.data.attributes.Banner_Text_Two.split("||");
  const [partOne2, partTwo2] =
    productPageData.data.attributes.Banner_Text_Three.split("||");
  const [partOne3, partTwo3] =
    productPageData.data.attributes.Banner_Text_Four.split("||");
  const [partOne4, partTwo4] =
    productPageData.data.attributes.Power_Heading.split("||");
  const [partOne5, partTwo5] =
    productPageData.data.attributes.Happen_Next_Heading.split("||");

  return (
    <>
      <ParentContainer
        containerHeight={"py-0"}
        containerStyle="bg-[#FBF7F5] flex justify-stretch flex-wrap max-lg:bg-white"
      >
        <div className="w-full bg-white px-8 py-6 flex max-lg:px-0 max-lg:flex-wrap max-lg:gap-8">
          <h1 className="text-xl font-bold pt-5 hidden max-md:block">
            {sourceData.data.attributes.Title}{" "}
          </h1>
          <div className=" w-1/2 max-lg:w-full">
            <div className="flex justify-stretch h-[345px] max-lg:h-auto max-lg:flex-wrap max-lg:gap-4 ">
              <div className="flex !hidden flex-col flex-wrap justify-between  w-1/4 h-full overflow-hidden max-lg:flex-row max-lg:flex-nowrap max-lg:w-full max-lg:order-1">
                <div className="w-full h-1/5 flex items-center justify-center overflow-hidden ">
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={assets.ProductImageOne}
                    alt=""
                  />
                </div>
                <div className="w-full h-1/5 flex items-center justify-center overflow-hidden ">
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={assets.ProductImageOne}
                    alt=""
                  />
                </div>
                <div className="w-full h-1/5 flex items-center justify-center overflow-hidden ">
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={assets.ProductImageOne}
                    alt=""
                  />
                </div>
                <div className="w-full h-1/5 flex items-center justify-center overflow-hidden ">
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={assets.ProductImageOne}
                    alt=""
                  />
                </div>
              </div>
              <div className="w-3/4 !w-full h-full overflow-hidden flex items-center justify-center max-lg:w-full">
                <img
                  className="max-w-full max-h-full object-contain w-full"
                  src={
                    ApiUrl +
                    sourceData?.data?.attributes?.Image?.data?.attributes?.url
                  }
                  alt=""
                />
              </div>
            </div>

            <div className="bg-[#F7F6FF] flex mt-4 items-center justify-between py-4 px-4 rounded-lg">
              <div className="flex items-center gap-2 hidden">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 55 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30.077 30.01H23.917V27.09H30.077V30.01Z"
                    fill="#37424A"
                  />
                  <circle cx="27.5" cy="27.5" r="27" stroke="#37424A" />
                </svg>

                <p className="font-bold text-xl ">1</p>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 55 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.605 26.33V22.73H28.445V26.33H32.065V29.09H28.445V32.69H25.605V29.09H21.985V26.33H25.605Z"
                    fill="#37424A"
                  />
                  <circle cx="27.5" cy="27.5" r="27" stroke="#37424A" />
                </svg>
              </div>
              <p className="font-bold text-xl max-md:text-base">
                Total: AED{" "}
                {Number(sourceData.data.attributes.Price).toLocaleString()}
              </p>
            </div>
            <Button
              Linkto={"/checkout"}
              titleof={"Get Started"}
              CustomStyle={"!items-center !px-0  !justify-start !pt-5"}
            />
          </div>
          <div className="w-1/2 max-lg:w-full pl-6 max-lg:pl-0">
            <div className=" border-b pb-6">
              <h1 className="text-xl font-bold max-md:hidden">
                {sourceData.data.attributes.Title}{" "}
              </h1>
              <p className="text-base">
                {" "}
                {sourceData.data.attributes.Sub_Title}{" "}
              </p>
            </div>
            <div className=" border-b pb-6">
              <h3 className="text-xl font-bold text-custom-primary pt-4">
                AED {Number(sourceData.data.attributes.Price).toLocaleString()}
              </h3>
              <p className="text-base">{sourceData.data.attributes.Para} </p>
            </div>
            <div className=" border-b pb-6">
              <h3 className="text-base font-bold  pt-4">
                This package includes:
              </h3>
              <div className="text-base">
                <ul className="list-disc pl-5">
                  {sourceData.data.attributes.Points.map((point, index) => (
                    <li key={index}>{point.List} </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className=" border-b py-6 flex max-md:flex-wrap max-md:gap-8">
              <div className="w-1/3 flex items-center max-md:w-full">
                <div className="pr-1">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.1875 20.625V23.25"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.93774 27.5H21.4377V26.25C21.4377 24.875 20.3127 23.75 18.9377 23.75H11.4377C10.0627 23.75 8.93774 24.875 8.93774 26.25V27.5V27.5Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                    <path
                      d="M7.6875 27.5H22.6875"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.9998 20C10.1623 20 6.24976 16.0875 6.24976 11.25V7.5C6.24976 4.7375 8.48726 2.5 11.2498 2.5H18.7498C21.5123 2.5 23.7498 4.7375 23.7498 7.5V11.25C23.7498 16.0875 19.8373 20 14.9998 20Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.83852 14.5625C5.90102 14.2625 5.07603 13.7125 4.42603 13.0625C3.30103 11.8125 2.55103 10.3125 2.55103 8.5625C2.55103 6.8125 3.92603 5.4375 5.67603 5.4375H6.48853C6.23853 6.0125 6.11352 6.65 6.11352 7.3125V11.0625C6.11352 12.3125 6.37602 13.4875 6.83852 14.5625Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.1621 14.5625C24.0996 14.2625 24.9246 13.7125 25.5746 13.0625C26.6996 11.8125 27.4496 10.3125 27.4496 8.5625C27.4496 6.8125 26.0746 5.4375 24.3246 5.4375H23.5121C23.7621 6.0125 23.8871 6.65 23.8871 7.3125V11.0625C23.8871 12.3125 23.6246 13.4875 23.1621 14.5625Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p className="text-base text-custom-primary font-semibold">
                  Free shipping
                </p>
              </div>

              <div className="w-1/3 flex items-center max-md:w-full">
                <div className="pr-1">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.55 16.9375C22.025 17.45 21.725 18.1875 21.8 18.975C21.9125 20.325 23.15 21.3125 24.5 21.3125H26.875V22.8C26.875 25.3875 24.7625 27.5 22.175 27.5H7.825C5.2375 27.5 3.125 25.3875 3.125 22.8V14.3875C3.125 11.8 5.2375 9.6875 7.825 9.6875H22.175C24.7625 9.6875 26.875 11.8 26.875 14.3875V16.1875H24.35C23.65 16.1875 23.0125 16.4625 22.55 16.9375Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.125 15.5121V9.7997C3.125 8.3122 4.0375 6.98715 5.425 6.46215L15.35 2.71215C16.9 2.12465 18.5625 3.27468 18.5625 4.93718V9.68717"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M28.1985 17.4624V20.0375C28.1985 20.725 27.6485 21.2875 26.9485 21.3125H24.4985C23.1485 21.3125 21.911 20.325 21.7985 18.975C21.7235 18.1875 22.0235 17.45 22.5485 16.9375C23.011 16.4625 23.6485 16.1875 24.3485 16.1875H26.9485C27.6485 16.2125 28.1985 16.7749 28.1985 17.4624Z"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.75 15H17.5"
                      stroke="#8884D5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p className="text-base text-custom-primary font-semibold">
                  AABB accredited lab
                </p>
              </div>
            </div>
            <div className="  pb-6">
              <h3 className="text-base font-bold  py-4 ">Add-ons:</h3>
              <div className="text-base flex items-center flex-wrap">
                <p className="font-semibold">
                  {productPageData.data.attributes.Addon_Heading}
                </p>
                <p>{productPageData.data.attributes.Addon_Para}</p>
              </div>
            </div>
            <div className=" border-b pb-6 hidden">
              <h3 className="text-base font-bold  pt-4">
                Additional information
              </h3>
              <div className="text-base">
                <ul className="list-disc pl-5">
                  <li>Enrollment: 2,000 AED</li>
                  <li>Processing Fee: 2,000 AED</li>
                  <li>Storage fee: 2,000 AED</li>
                  <li>Total: 37500</li>
                  <li>Processing Discount: 1,973 AED</li>
                  <li>Storage Discount: 5,921 AED</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-white">
          <div className=" bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg justify-between  mx-8 flex items-baseline py-8 max-lg:mx-0 max-lg:flex-wrap max-lg:gap-8">
            <div className="w-1/5 flex flex-wrap justify-center gap-1 max-lg:w-full">
              <h4 className="w-full leading-10 text-center text-2xl font-bold  text-white">
                {partOne}
              </h4>
              <p className="w-4/5 text-center text-lg font-semibold text-white  ">
                {partTwo}
              </p>
            </div>
            <div className="w-1/5 flex flex-wrap justify-center gap-1 max-lg:w-full">
              <h4 className="w-full leading-10 text-center text-2xl font-bold  text-white">
                {partOne1}
              </h4>
              <p className="w-5/5 text-center text-lg font-semibold text-white  ">
                {partTwo1}
              </p>
            </div>
            <div className="w-1/5 flex flex-wrap justify-center gap-1 max-lg:w-full">
              <h4 className="w-full leading-10 text-center text-2xl font-bold  text-white">
                {partOne2}
              </h4>
              <p className="w-4/5 text-center text-lg font-semibold text-white  ">
                {partTwo2}
              </p>
            </div>
            <div className="w-1/5 flex flex-wrap justify-center gap-1 max-lg:w-full">
              <h4 className="w-full leading-10 text-center text-2xl font-bold  text-white">
                {partOne3}
              </h4>
              <p className="w-4/5 text-center text-lg font-semibold text-white  ">
                {partTwo3}
              </p>
            </div>
          </div>
        </div>

        <MainValuable
          HeadingBlack={partOne4}
          HeadingBlue={partTwo4}
          fontSizesH={"!text-4xl"}
          HeadingPara={productPageData.data.attributes.Power_Para}
          width={"!px-8 bg-white w-full max-lg:!px-0"}
          isButton={false}
          ValueList={productPageData.data.attributes.Power_Points.map(
            (point, index) => point.List
          )}
        />
        <div className="bg-white p-8 max-lg:px-0 ">
          <h2 className="text-4xl  font-bold pb-5 max-md:text-2xl">
            {partOne5} <span className=" text-custom-primary ">{partTwo5}</span>
          </h2>

          <div className="flex flex-wrap py-10  max-lg:gap-8">
            <div className="w-1/2  border-l flex flex-col gap-5 max-lg:w-full">
              <div className="mr-5">
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    {productPageData.data.attributes.Happen_Next_One}
                    <p className="text-base font-normal">
                      {productPageData.data.attributes.Happen_Next_One_Para}
                    </p>
                  </div>
                </div>
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    {productPageData.data.attributes.Happen_Next_Two}
                    <p className="text-base font-normal">
                      {productPageData.data.attributes.Happen_Next_Two_Para}
                    </p>
                  </div>
                </div>
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    {productPageData.data.attributes.Happen_Next_Three}
                    <p className="text-base font-normal">
                      {productPageData.data.attributes.Happen_Next_Three_Para}
                    </p>
                  </div>
                </div>
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    {productPageData.data.attributes.Happen_Next_Four}
                    <p className="text-base font-normal">
                      {productPageData.data.attributes.Happen_Next_Four_Para}
                    </p>
                  </div>
                </div>
                <div class="flex items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full ml-1">
                  <div class="absolute -left-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    {productPageData.data.attributes.Happen_Next_Five}
                    <p className="text-base font-normal">
                      {productPageData.data.attributes.Happen_Next_Five_Para}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 max-lg:w-full">
              <div className="h-full bg-white">
                <iframe
                  src="https://wallycle.sirv.com/Cell%20Save%2002/Cell%20Save%2002.spin%0A#sirv-viewer-1329217373685"
                  className="w-full h-full border-0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="p-8 flex flex-wrap justify-center bg-white w-full max-lg:px-0 max-lg:pb-4 max-lg:justify-start">
          <h3 className="text-4xl  w-1/2 font-bold pb-5 max-md:text-2xl">
            {productPageData.data.attributes.FAQs_Heading}
          </h3>
          <Button
            Linkto={"/checkout"}
            titleof={" Order Now"}
            CustomStyle={"  box max-lg:mb-4 !justify-end items-center !px-0"}
          />
          <div className="flex flex-col w-full border border-[#EAECF0] rounded-lg ">
            {productPageData.data.attributes.FAQs.map((faq, index) => (
              <AccordionSetm
                title={faq.Question}
                isOpen={openIndex === index}
                onClick={() => handleToggle(index)}
              >
                <p>{faq.Answer}</p>
              </AccordionSetm>
            ))}
          </div>
        </div>
      </ParentContainer>
    </>
  );
};

export default ProductPage;
