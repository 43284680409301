import React, { useEffect, useState } from "react";
import MainHeroSection from "../components/MainHeroSection";
import Sources from "./SourceOfStem/Sources";
import assets from "../assets/assets";
import Power from "./SourceOfStem/Power";
import TranslplantNumbers from "../components/TranslplantNumbers";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";
gsap.registerPlugin(ScrollTrigger);

const SourcesOfStem = () => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/sources-of-stem-cell?populate=Translpant_Numbers,Translpant_Numbers.image,Hero_Section,Hero_Section.Video,Faqs,Faqs.Points,Transformative_Image"
        );

        setSource(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }
  const [beforeDelimiter, afterDelimiter] =
    source.data.attributes.Hero_Section.Heading.split("||");

  const lineBreak = false;
  return (
    <div>
      <MainHeroSection
        HeadingBlack={beforeDelimiter}
        HeadingBreak={lineBreak}
        HeadingBlue={afterDelimiter}
        HeadingPara={source.data.attributes.Hero_Section.Paragraph}
        IconOne={source.data.attributes.Hero_Section.Point_One}
        IconTwo={source.data.attributes.Hero_Section.Point_Two}
        IconThree={source.data.attributes.Hero_Section.Point_Three}
        BannerImage={
          ApiUrl + source.data.attributes.Hero_Section.Video.data.attributes.url
        }
        BannerText={source.data.attributes.Hero_Section.Video_Heading}
      />
      <Sources
        data={source.data.attributes}
        title={source.data.attributes.About_Heading}
      />
      <Power data={source.data.attributes} />
      <TranslplantNumbers
        backgroundImage={
          ApiUrl +
          source.data.attributes.Translpant_Numbers.image.data.attributes.url
        }
        mobileBackgroundImage={assets.mt3}
        numberone={source.data.attributes.Translpant_Numbers.Number_One}
        numbertwo={source.data.attributes.Translpant_Numbers.Number_Two}
        numberthree={source.data.attributes.Translpant_Numbers.Number_Three}
        textone={source.data.attributes.Translpant_Numbers.Text_One}
        texttwo={source.data.attributes.Translpant_Numbers.Text_Two}
        textthree={source.data.attributes.Translpant_Numbers.Text_Three}
        title={source.data.attributes.Translpant_Numbers.Heading}
      />
    </div>
  );
};

export default SourcesOfStem;
