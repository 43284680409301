import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import assets from "../assets/assets";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ApiUrl } from "../components/ApiUrl";
import { useNavigate } from "react-router-dom";

const Popup = ({ setIsVisible, isVisible, promo }) => {
  const [phone, setPhone] = useState("");
  const [to, setTo] = useState("it@cellsave.com"); // Default recipient email
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const navigate = useNavigate();
  const loginContainerRef = useRef(null);

  useEffect(() => {
    const lastClosed = localStorage.getItem("popupClosed");
    if (lastClosed) {
      const now = new Date().getTime();
      const diff = now - lastClosed;
      // Show popup if 24 hours have passed since last closed
      if (diff > 24 * 60 * 60 * 1000) {
        setTimeout(() => {
          setIsVisible(true);
        }, 6000);
      }
    } else {
      // Show popup if it hasn't been closed before
      setTimeout(() => {
        setIsVisible(true);
      }, 6000);
    }
  }, [setIsVisible]);

  const handleClose = () => {
    localStorage.setItem("popupClosed", new Date().getTime());
    setIsVisible(false);
  };

  const handleClickOutside = (event) => {
    if (
      loginContainerRef.current &&
      !loginContainerRef.current.contains(event.target)
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEmailChange = (event) => {
    setTo(event.target.value); // Update the "to" state with the input value
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Custom validation for phone number
    if (!phone || phone.trim().length < 10) {
      // Adjust length as per your requirements
      alert("Please enter a valid phone number.");
      return;
    }

    setButtonText("Sending...");
    const formattedPhone = phone.replace(/(\d{3})(\d{2})(\d{7})/, "971-$2-$3");

    const formElements = event.target.elements;

    const recipientEmails = to
      ? `${to}, marketing@cellsave.com, rima.kobeissi@cellsave.com, tala.hammami@cellsave.com, Nadya.abbas@cellsave.com`
      : "marketing@cellsave.com, rima.kobeissi@cellsave.com, tala.hammami@cellsave.com, Nadya.abbas@cellsave.com";

    const formData = {
      to: recipientEmails,
      subject: subject || "New Popup Form Submission",
      text: text || "You have a new message from the Popup form.",
      html: `
        <p><strong>First Name:</strong> ${formElements["first-name"].value}</p>
        <p><strong>Last Name:</strong> ${formElements["last-name"].value}</p>
        <p><strong>Phone Number:</strong> ${formattedPhone}</p>
        <p><strong>Email:</strong> ${formElements.email.value}</p>
        <p><strong>Due Date:</strong> ${formElements["due-date"].value}</p>
      `,
    };
    console.log(formattedPhone);
    console.log(formElements["due-date"].value);

    const saleforceData = {
      FirstName: formElements["first-name"].value,
      LastName: formElements["last-name"].value,
      Email: formElements.email.value,
      MobilePhone: formattedPhone,
      Origin_Program__c: "Download Info Guide",
      Lead_Quality__c: "Hot",
      AttendingDoctor__c: "a00f400000XCppCAAT",
      Hospital_Clinics__c: "a01f400000KF5VMAA1",
      Program_Running__c: "Website",
      Origin_Hospital__c: "a01f400000KF5VMAA1",
      Origin_Doctor__c: "a00f400000XCppCAAT",
      Due_Date__c: formElements["due-date"].value,
    };

    try {
      const [emailResponse, saleforceResponse] = await Promise.all([
        axios.post(`${ApiUrl}/api/email/send`, formData),
        axios.post(`${ApiUrl}/api/salesforce`, saleforceData),
      ]);

      console.log("Email sent successfully:", emailResponse.data);
      console.log("Salesforce data sent successfully:", saleforceResponse.data);
      setButtonText("Sent Successfully");
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      setButtonText("Submit"); // Reset button text in case of error
      return;
    } finally {
      // Set the location to the desired URL
      window.location.href = "https://cellsave.com/CellSave_Info_Guide-3.pdf";

      // Reset button text after a delay of 3 seconds
      setTimeout(() => {
        setButtonText("Submit");
      }, 3000);
    }
  };

  return (
    isVisible && (
      <div className="fixed top-0 left-0 w-full z-[9999] flex justify-center bg-[#00000075] h-screen items-center overflow-auto">
        <div
          ref={loginContainerRef}
          className="bg-white w-2/5 py-20 rounded-2xl relative px-10 flex flex-col gap-5 max-md:w-full max-md:py-10 max-h-[90vh] overflow-y-auto"
          style={{
            backgroundImage: `url(${assets.popupimg})`,
            backgroundSize: "30%",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            backgroundPositionY: "top",
          }}
        >
          <h3 className="text-5xl font-bold text-custom-primary max-md:text-xl">
            Not ready{" "}
            <span className="max-md:hidden">
              <br />
            </span>
            to enroll yet?
          </h3>
          <p className="text-xl font-semibold max-md:text-base w-4/5">
            Request your <span className="text-custom-primary">FREE</span> info
            kit about stem cells and get{" "}
            <span className="text-custom-primary">10%</span> off on your
            purchase
          </p>
          <form onSubmit={handleSubmit} className="grid grid-cols-4 gap-2">
            <div className="col-span-2 flex flex-col gap-2">
              <label className="font-semibold" htmlFor="first-name">
                First Name
              </label>
              <input
                className="bg-[#F6F6F6] p-3 rounded-lg"
                type="text"
                name="first-name"
                id="first-name" // Added id for accessibility
                placeholder="First Name"
                required
              />
            </div>
            <div className="col-span-2 flex flex-col gap-2">
              <label className="font-semibold" htmlFor="last-name">
                Last Name
              </label>
              <input
                className="bg-[#F6F6F6] p-3 rounded-lg"
                type="text"
                name="last-name"
                id="last-name" // Added id for accessibility
                placeholder="Last Name"
                required
              />
            </div>
            <div className="col-span-2 flex flex-col gap-2">
              <label className="font-semibold" htmlFor="email">
                Email
              </label>
              <input
                className="bg-[#F6F6F6] p-3 rounded-lg text-base"
                type="email"
                name="email"
                id="email" // Added id for accessibility
                placeholder="Email"
                required
              />
            </div>
            <div className="col-span-2 flex flex-col gap-2">
              <label className="font-semibold" htmlFor="phone-number">
                Phone Number
              </label>
              <PhoneInput
                country={"ae"}
                className="bg-[#F6F6F6] p-3 rounded-lg text-base"
                style={{ padding: "0.65rem 0" }}
                inputProps={{
                  name: "phone-number",
                  id: "phone-number", // Added id to associate with label
                  required: true, // Make phone number required
                }}
                placeholder="Enter phone number"
                enableLongNumbers={false} // Changed to boolean
                value={phone}
                onChange={setPhone}
              />
            </div>
            <div className="col-span-4 flex flex-col gap-2">
              <label className="font-semibold" htmlFor="due-date">
                Due Date
              </label>
              <input
                className="bg-[#F6F6F6] p-3 rounded-lg text-base"
                type="date"
                name="due-date"
                id="due-date" // Added id for accessibility
                placeholder="Due Date"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-custom-primary to-custom-secondary text-white px-5 py-3 mt-5 rounded-3xl font-semibold col-span-4"
            >
              {buttonText}
            </button>
          </form>
          <div
            className="absolute left-5 top-5 cursor-pointer max-md:top-2 max-md:left-2"
            onClick={handleClose}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="max-md:w-[32px]"
            >
              <path
                d="M24 44C35 44 44 35 44 24C44 13 35 4 24 4C13 4 4 13 4 24C4 35 13 44 24 44Z"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3398 29.6598L29.6598 18.3398"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M29.6598 29.6598L18.3398 18.3398"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    )
  );
};

export default Popup;
