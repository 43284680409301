import React from "react";
import ParentContainer from "../../components/ParentContainer";
import { ApiUrl } from "../../components/ApiUrl";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import {
  BlocksRenderer,
  type BlocksContent,
} from "@strapi/blocks-react-renderer";
import { Link } from "react-router-dom";

const BlogMainSection = ({ data }) => {
  return (
    <ParentContainer>
      <h1 className=" text-3xl text-custom-primary font-bold max-md:text-2xl">
        {data.attributes.Title}
      </h1>
      <div className="pt-5 flex items-center gap-10 pb-10">
        <p className="text-base bg-custom-primary py-2 px-3 text-white font-semibold rounded-lg text-center inline ">
          {data.attributes.blog_category.data.attributes.Category}
        </p>
        <div className="flex gap-4">
          {/* <Link to="" href="">
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.97331 2.75C5.73482 2.74982 5.49862 2.79663 5.27822 2.88775C5.05781 2.97887 4.85752 3.11253 4.68878 3.28107C4.52004 3.44962 4.38616 3.64976 4.29479 3.87006C4.20342 4.09036 4.15634 4.3265 4.15625 4.565C4.15625 5.56875 4.96956 6.40063 5.97125 6.40063C6.97431 6.40063 7.78831 5.56875 7.78831 4.56638C7.78849 4.32791 7.74168 4.09175 7.65055 3.87138C7.55942 3.65102 7.42575 3.45077 7.2572 3.28209C7.08864 3.1134 6.8885 2.97959 6.6682 2.88829C6.44791 2.79699 6.21178 2.75 5.97331 2.75ZM14.8366 7.5625C13.311 7.5625 12.4386 8.36 12.0192 9.15338H11.9752V7.77631H8.96875V17.875H12.1017V12.8762C12.1017 11.5589 12.2007 10.2857 13.8308 10.2857C15.4374 10.2857 15.4608 11.7879 15.4608 12.9594V17.875H18.5938V12.3282C18.5938 9.614 18.0101 7.5625 14.8366 7.5625ZM4.40581 7.77563V17.875H7.54081V7.77563H4.40581Z"
                fill="#8884D5"
              />
            </svg>
          </Link> */}
          <Link to="https://www.facebook.com/cellsavearabia/" href="">
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2744 10.125L13.7743 6.86742H10.6486V4.75348C10.6486 3.86227 11.0852 2.99355 12.4852 2.99355H13.9062V0.220078C13.9062 0.220078 12.6166 0 11.3837 0C8.80957 0 7.12699 1.56023 7.12699 4.38469V6.86742H4.26562V10.125H7.12699V18H10.6486V10.125H13.2744Z"
                fill="#8884D5"
              />
            </svg>
          </Link>
          <Link to="https://www.instagram.com/cellsavearabia/" href="">
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0749 3.14258H7.58285C5.17209 3.14258 3.21777 5.09689 3.21777 7.50766V14.4918C3.21777 16.9025 5.17209 18.8569 7.58285 18.8569H14.567C16.9777 18.8569 18.9321 16.9025 18.9321 14.4918V7.50766C18.9321 5.09689 16.9777 3.14258 14.567 3.14258H11.0749Z"
                stroke="#8884D5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0741 7.50781C13.0027 7.50781 14.5662 9.07126 14.5662 10.9999C14.5662 12.9285 13.0027 14.4919 11.0741 14.4919C9.14548 14.4919 7.58203 12.9285 7.58203 10.9999C7.58203 9.07126 9.14548 7.50781 11.0741 7.50781Z"
                stroke="#8884D5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.6123 7.79199C16.3717 7.79199 16.9873 7.17638 16.9873 6.41699C16.9873 5.6576 16.3717 5.04199 15.6123 5.04199C14.8529 5.04199 14.2373 5.6576 14.2373 6.41699C14.2373 7.17638 14.8529 7.79199 15.6123 7.79199Z"
                fill="#8884D5"
              />
            </svg>
          </Link>
        </div>
      </div>
      {data.attributes.Image.data && (
        <img
          src={ApiUrl + data.attributes.Image.data.attributes.url}
          alt=""
          className="h-[450px]  w-full  rounded-lg mb-10  max-lg:h-[250px]  object-cover object-center"
        />
      )}

      <div
        className="[&_ul]:list-disc [&_ul]:pl-8"
        id="blogHTML"
        dangerouslySetInnerHTML={{ __html: data.attributes.Body }}
      />
    </ParentContainer>
  );
};

export default BlogMainSection;
