import React, { useEffect, useState } from "react";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";
import TranslplantNumbers from "../components/TranslplantNumbers";
import LifeTimeBanner from "../components/LifeTimeBanner";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";

const CordTissue = () => {
  const lineBreak = false;
  const AdvantagePara = true;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/cord-tissue?populate=Translpant_Numbers,Translpant_Numbers.image,Hero_Section,Hero_Section.Video,Advantages,Advantages.Image,Advantages.Advantages,Valuable,Valuable.Points"
        );

        setData(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!data) {
    return <div>Error loading data.</div>;
  }
  const [beforeDelimiter, afterDelimiter] =
    data.data.attributes.Hero_Section.Heading.split("||");
  const [beforeDelimiter1, afterDelimiter1] =
    data.data.attributes.Advantages.Heading.split("||");
  const [beforeDelimiter2, afterDelimiter2] =
    data.data.attributes.Valuable.Heading.split("||");

  return (
    <div>
      <MainHeroSection
        HeadingBlack={beforeDelimiter}
        HeadingBreak={lineBreak}
        HeadingBlue={afterDelimiter}
        HeadingPara={data.data.attributes.Hero_Section.Paragraph}
        IconOne={data.data.attributes.Hero_Section.Point_One}
        IconTwo={data.data.attributes.Hero_Section.Point_Two}
        IconThree={data.data.attributes.Hero_Section.Point_Three}
        BannerImage={
          ApiUrl + data.data.attributes.Hero_Section.Video.data.attributes.url
        }
        BannerText={data.data.attributes.Hero_Section.Video_Heading}
      />
      <MainAdvantagesSection
        HeadingBlack={beforeDelimiter1}
        HeadingBlue={afterDelimiter1}
        isPara={true}
        TwoImages={false}
        Image={
          ApiUrl + data.data.attributes.Advantages.Image.data.attributes.url
        }
        Para={data.data.attributes.Advantages.Para}
        Advantages={data.data.attributes.Advantages.Advantages.map(
          (advantage, index) => advantage.List
        )}
      />
      <MainValuable
        HeadingBlack={beforeDelimiter2}
        HeadingBlue={afterDelimiter2}
        HeadingPara={data.data.attributes.Valuable.Para}
        isButton={false}
        ValueList={data.data.attributes.Valuable.Points.map(
          (point, index) => point.List
        )}
      />
      <TranslplantNumbers
        backgroundImage={
          ApiUrl +
          data.data.attributes.Translpant_Numbers.image.data.attributes.url
        }
        mobileBackgroundImage={assets.mt5}
        numberone={data.data.attributes.Translpant_Numbers.Number_One}
        numbertwo={data.data.attributes.Translpant_Numbers.Number_Two}
        numberthree={data.data.attributes.Translpant_Numbers.Number_Three}
        textone={data.data.attributes.Translpant_Numbers.Text_One}
        texttwo={data.data.attributes.Translpant_Numbers.Text_Two}
        textthree={data.data.attributes.Translpant_Numbers.Text_Three}
        title={data.data.attributes.Translpant_Numbers.Heading}
      />
      <LifeTimeBanner Heading={data.data.attributes.Banner_Text} />
    </div>
  );
};

export default CordTissue;
