import React, { useEffect, useState } from "react";
import HeroSection from "./StemCellsScience/HeroSection";
import ThreeBox from "./StemCellsScience/ThreeBox";
import Reason from "./StemCellsScience/Reasons";
import Medicine from "./StemCellsScience/Medicine";
import MedTabs from "./StemCellsScience/MedTabs";
import RegMed from "./StemCellsScience/RegMed";
import HowWorks from "./StemCellsScience/HowWorks";
import FAQs from "./StemCellsScience/FAQs";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import { useLocation } from "react-router-dom";
import { ApiUrl } from "../components/ApiUrl";
import axios from "axios";

const StemCellsSciencePage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const [stemCell, setStemCell] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/stem-cell?populate=Hero_Section,Hero_Section.Video,Impact_Med_Image_One,Impact_Med_Image_Two,Impact_Med_Image_Three,Translpant_Meds_Tab_Content_One,Translpant_Meds_Tab_Bg,Translpant_Meds_Tab_Content_Two,Translpant_Meds_Tab_Content_Three,Translpant_Meds_Tab_Content_Four,Reg_Med_Image,Reg_Med_Faqs,How_Work_Slider,How_Work_Slider.Image,FAQs"
        );
        setStemCell(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!stemCell) {
    return <div>Error loading data.</div>;
  }
  return (
    <div className="bg-[#FBF7F5]">
      <HeroSection data={stemCell.data.attributes.Hero_Section} />
      <ThreeBox data={stemCell.data.attributes} />
      <Reason data={stemCell.data.attributes} />
      <Medicine data={stemCell.data.attributes} />
      <MedTabs data={stemCell.data.attributes} />
      <RegMed data={stemCell.data.attributes} />
      <HowWorks data={stemCell.data.attributes} />
      <FAQs data={stemCell.data.attributes} />
    </div>
  );
};

export default StemCellsSciencePage;
