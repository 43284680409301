import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutSection = ({ data }) => {
  const [beforeDelimiter, afterDelimiter] =
    data.data.attributes.Kit_Section_Heading.split("||");
  const [isVisible, setIsVisible] = useState(false);

  const main = useRef();

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        ".animate",
        { y: 100, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: ".animate",
            start: "top 80%",
            end: "top 40%",
            scrub: 1,
          },
        }
      );
    }, main); // Scope animations to 'main' reference

    return () => ctx.revert(); // Clean up the animation on unmount
  }, []);

  return (
    <ParentContainer
      containerStyle={`flex justify-center flex-wrap gap-5 py-40 max-md:py-20 ${
        isVisible ? "animate__animated animate__fadeInUp" : ""
      }`}
    >
      <div ref={main} className="">
        <div className="w-full flex justify-center flex-wrap gap-5 animate">
          <Button
            Linkto={"/stem-cells-sceince"}
            titleof={data.data.attributes.Kit_Section_Button_Text}
            CustomStyle={"  "}
          />
          <h2 className="text-5xl font-bold text-center w-1/2 max-md:w-full mx-5 max-md:mx-0 max-md:text-2xl">
            {beforeDelimiter}
            <span className="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text ">
              {afterDelimiter}
            </span>
          </h2>
          <p className="w-1/2 text-center text-xl max-md:w-full max-md:text-sm">
            {data.data.attributes.Kit_Section_Para}
          </p>
        </div>
      </div>
    </ParentContainer>
  );
};

export default AboutSection;
