import React, { useEffect, useState } from "react";
import ParentContainer from "../components/ParentContainer";
import assets from "../assets/assets";
import { ApiUrl } from "../components/ApiUrl";
import axios from "axios";
import { useParams } from "react-router-dom";

const FeaturedMomsSingle = () => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + `/api/blogs/${id}?populate=*`
        );

        setSource(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }
  // const [
  //   beforeDelimiter,
  //   afterDelimiter,
  // ] = source.data.attributes.Hero_Section.Heading.split("||");

  console.log("Moms", source);
  return (
    <ParentContainer containerStyle={"flex max-md:flex-wrap"}>
      <div className="w-1/3 max-md:w-full">
        <img
          src={ApiUrl + source.data.attributes.Image.data.attributes.url}
          alt=""
        />
      </div>
      <div className="w-2/3 pl-5 flex flex-col gap-4 max-md:w-full max-md:pl-0 max-md:pt-4">
        <h3 className="text-3xl font-bold text-custom-primary">
          {source.data.attributes.Title}
        </h3>
        <div
          dangerouslySetInnerHTML={{ __html: source.data.attributes.Body }}
        />
      </div>
    </ParentContainer>
  );
};

export default FeaturedMomsSingle;
